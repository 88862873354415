<template>
  <v-app>
    <v-dialog v-model="openModal" min-width="200" max-width="700" persistent>
      <v-card>
        <v-card-title class="headline red lighten-1"> ERROR </v-card-title>
        <v-card-text>
          <br />
          <h6>This phone number/email already registered:</h6>
        </v-card-text>
        <v-data-table
          item-key="id"
          :headers="headers"
          :items="errorDate.filter((v, i) => i < 10)"
          :items-per-page="-1"
          mobile-breakpoint="1"
          hide-default-footer
        >
        </v-data-table>
        <div v-if="errorDate && errorDate.length > 10" class="text-center">
          <span class="more-errors-user"
            >We have sent a full list of errors to your email</span
          >
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          {{ totalLoaded }}/{{ totalUsers }} record loaded
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeModal"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
export default {
  name: 'RegisteredPhonesTable',
  props: {
    errorModal: {
      type: Boolean,
    },
    totalLoaded: {
      type: Number,
    },
    totalUsers: {
      type: Number,
    },
    errorDate: {
      type: Array,
    },
    headers: {
      type: Array,
    },
  },
  data: () => ({
    openModal: false,
  }),
  watch: {
    errorModal(newValue) {
      this.openModal = newValue;
    },
  },
  methods: {
    closeModal() {
      this.openModal = false;
      this.$emit('close-modal', false);
    },
  },
};
</script>

<style scoped>
</style>
